import {GLOBAL_STYLES} from "../mainConstants/GLOBAL_STYLES";
import {BASE_MEDIA} from "../mainConstants/BASE_MEDIA";
import {makeStyles} from "@mui/styles";
import {BrowserRouter} from "react-router-dom";
import {useMediaQuery} from "@material-ui/core";
import {useState} from "react";
import {LocalDatabase} from "../api/localDatabase/LocalDatabase";
import 'react-toastify/dist/ReactToastify.css';
import Router from "./router/Router";

const useStyles = makeStyles({
    container: {
        maxWidth: GLOBAL_STYLES.containerWidth,
        margin: "auto",
        position: "relative",
        minHeight: "100vh"
    },

    suspenseLoading: {
        width: "100%",
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        paddingTop: "10vh",
    }
})

function App() {
    const isExtraSmallScreen = useMediaQuery(BASE_MEDIA.extraSmallScreen)
    const isMiddleScreen = useMediaQuery(BASE_MEDIA.middleScreen, { noSsr: true })
    const footerHeight = isMiddleScreen ? (isExtraSmallScreen ? "650px" : "620px") : "340px"
    const styles = useStyles({ footerHeight })
    const [token, setToken] = useState(LocalDatabase.getToken())

    return (
        <section className={styles.container}>
            <BrowserRouter>
                <Router footerHeight={footerHeight}
                        token={token}
                        setToken={setToken}/>
            </BrowserRouter>
        </section>
    )
}

export default App