export const BASE_FONTS = {
    openSans: {
        regular: "OpenSans-Regular",
        bold: "OpenSans-Bold",
        semiBold: "OpenSans-SemiBold"
        // Light 300, Normal 400, Medium 500, Semi-Bold 600, Bold 700 and Extra Bold 800
    },

    playFairDisplay: {
        regular: "PlayfairDisplay-Regular",
        bold: "PlayfairDisplay-Bold"
    }
}