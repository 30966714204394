import {makeStyles} from "@mui/styles";
import {useParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {URL_TYPES} from "../../mainConstants/URL_TYPES";
import {BASE_FONTS} from "../../mainConstants/BASE_FONTS";
import {BASE_IMAGES} from "../../mainConstants/BASE_IMAGES";
import CustomButton from "../main/components/button/CustomButton";
import {BASE_COLORS} from "../../mainConstants/BASE_COLORS";
import {BASE_ROUTERS} from "../../mainConstants/BASE_ROUTERS";
import {useTranslation} from "react-i18next";
import {useMediaQuery} from "@material-ui/core";
import {BASE_MEDIA} from "../../mainConstants/BASE_MEDIA";

const useStyles = makeStyles({
    notFoundInfoContainer: {
        position: "absolute",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    },

    notFoundInfoContainer2: {
        width: "100%",
        textAlign: "center",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        rowGap: "20px",
        padding: "0 10px"
    },

    notFoundTitle: {
        fontFamily: BASE_FONTS.openSans.bold,
        fontSize: props => props.isMidScreen ? "30px" : "40px"
    },

    notFoundSubtitle: {
        fontFamily: BASE_FONTS.openSans.regular,
        fontSize: props => props.isMidScreen ? "15px" : "20px"
    }
})

function ProductsRoute({ footerHeight }) {

    const isMidScreen = useMediaQuery(BASE_MEDIA.middleScreen)
    const styles = useStyles({ isMidScreen })
    const {t} = useTranslation()

    useEffect(() => {
        document.body.style.overflow = "hidden"

        return () => {
            document.body.style.overflow = "auto"
        }
    }, [])

    return (
        <>
            <div className={styles.notFoundInfoContainer}>
                <img src={BASE_IMAGES.notFoundImage} alt={""}/>
            </div>
            <div className={`${styles.notFoundInfoContainer} ${styles.notFoundInfoContainer2}`}>
                <h1 className={styles.notFoundTitle}>{t("notFoundTitle")}</h1>
                <p className={styles.notFoundSubtitle}>{t("notFoundSubtitle")}</p>
                <CustomButton title={t("notFoundReturnButton")}
                              width={"200px"}
                              radius={10}
                              navLink={BASE_ROUTERS.main}
                              backgroundColor={BASE_COLORS.beige}/>
            </div>
        </>
    )
}

export default ProductsRoute