export const URL_TYPES = {
    "high-jewellery": ["high-jewellery", "vysokoye-yuvelirnoye-iskusstvo", "bardzr-voskerchakan-arvest"],
    "women": ["women", "zhenskiye", "kanaci"],
    "women-rings": ["women-rings", "zhenskiye-koltsa", "kanaci-mataniner"],
    "women-earrings": ["women-earrings", "zhenskiye-sergi", "kanaci-akanjogher"],
    "women-pendants": ["women-pendants", "zhenskiye-podveski", "kanaci-kakhazarder"],
    "women-necklaces": ["women-necklaces", "zhenskiye-kolye", "kanaci-vznocner"],
    "women-bracelets": ["women-bracelets", "zhenskiye-braslety", "kanaci-tevnocner"],
    "women-watches": ["women-watches", "zhenskiye-chasy", "kanaci-jamacuycner"],
    "women-certificated-diamonds": ["certificated-diamonds", "sertifitsirovannyye-brillianty", "havastagrvac-adamandner"],
    "women-yellow-diamonds": ["yellow-diamonds", "zheltyye-brillianty", "deghin-adamandner"],
    "women-natural-gems": ["natural-gems", "naturalnyye-dragotsennyye-kamni", "bnakan-tankarjeq-qarer"],
    "women-fancy-cut-gems": ["fancy-cut-gems", "fantaziynyye-kamni", "fantazia-tashvacqov-qarer"],
    "men": ["men", "muzhskiye", "tghamardu"],
    "men-ring": ["men-ring", "muzhskiye-koltsa", "tghamardu-mataniner"],
    "men-earrings": ["men-earrings", "muzhskiye-sergi", "tghamardu-akanjogher"],
    "men-bracelets": ["men-bracelets", "muzhskiye-braslety", "tghamardu-tevnocner"],
    "men-watches": ["men-watches", "muzhskiye-chasy", "tghamardu-jamacuycner"],
    "engagement&bridal": ["engagement&bridal", "pomolvka-svadba", "nshanadrutyun-harsaniq"],
    "engagement": ["engagement-rings", "pomolvochnyye-koltsa", "nshanadrutyan-mataniner"],
    "wedding": ["wedding-bands", "obruchalnyye-koltsa", "amusnakan-mataniner"],
    "new-in": ["newIn", "novyye", "nor-tesakani"],
}