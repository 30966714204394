export const BASE_IMAGES = {
    logoIcon: '/assets/logo_icon.png',
    searchIcon: '/assets/search_icon.png',
    locationIcon: '/assets/location_icon.png',
    locationSelectedIcon: '/assets/location_selected_icon.png',
    accountIcon: '/assets/account_icon.png',
    accountSelectedIcon: '/assets/account_selected_icon.png',
    favouriteIcon: '/assets/favourite_icon.png',
    selectedFavouriteIcon: '/assets/favourite_selected_icon.png',
    addressIcon: '/assets/address_icon.png',
    phoneIcon: '/assets/phone_icon.png',
    facebookIcon: '/assets/facebook_icon.png',
    instagramIcon: '/assets/instagram_icon.png',
    addressIconBlack: '/assets/address_icon_black.png',
    phoneIconBlack: '/assets/phone_icon_black.png',
    facebookIconBlack: '/assets/facebook_icon_black.png',
    instagramIconBlack: '/assets/instagram_icon_black.png',
    upArrowIcon: '/assets/up_arrow_icon.png',
    rightArrowIcon: '/assets/right_arrow_icon.png',
    downArrowIcon: '/assets/down_arrow_icon.png',
    leftArrowIcon: '/assets/left_arrow_icon.png',
    rightSideIcon: '/assets/right_side_icon.png',
    leftSideIcon: '/assets/left_side_icon.png',
    showPasswordIcon: '/assets/show_password_icon.png',
    hidePasswordIcon: '/assets/hide_password_icon.png',
    jewellery: '/assets/jewellery.png',
    accountLine: '/assets/account_line.png',
    xIcon: '/assets/x_icon.png',
    navIcon: '/assets/nav_icon.png',
    openDropdownIcon: '/assets/open_dropdown_icon.png',
    closeDropdownIcon: '/assets/close_dropdown_icon.png',
    checkMarkIcon: '/assets/check_mark_icon.png',
    greenCheckMarkIcon: '/assets/green_check_mark_icon.png',
    diamondIcon: "/assets/diamond_icon.png",
    sizeGuideIcon: "/assets/size_guide_icon.png",
    plusIcon: "/assets/plus_icon.png",
    diamondsImage: "/assets/diamonds_image.png",
    sizeGuideImage: "/assets/size_guide_image.png",
    soldOutIcon: "/assets/sold_out_icon.png",
    soldOutIconForAdmin: "/assets/sold_out_icon_2.png",
    editIcon: "/assets/edit_icon.png",
    deleteIcon: "/assets/delete_icon.png",
    filterIcon: "/assets/filter_icon.png",
    packagingAndShippingBanner: "/assets/footerServices/packaging_and_shipping_banner.png",
    packaging: "/assets/footerServices/packaging.png",
    shipping: "/assets/footerServices/shipping.png",
    engravingAndResizingBanner: "/assets/footerServices/engraving_and_resizing_banner.png",
    engraving: "/assets/footerServices/engraving.png",
    resizing: "/assets/footerServices/resizing.png",
    repairAndExchangeBanner: '/assets/footerServices/repair_and_exchange_banner.png',
    repair: '/assets/footerServices/repair.png',
    exchange: '/assets/footerServices/exchange.png',
    gemCutRoundIcon: "/assets/gem_cut_icons/gem_cut_round.png",
    gemCutPearIcon: "/assets/gem_cut_icons/gem_cut_pear.png",
    gemCutOvalIcon: "/assets/gem_cut_icons/gem_cut_oval.png",
    gemCutMarquiseIcon: "/assets/gem_cut_icons/gem_cut_marquise.png",
    gemCutHeartIcon: "/assets/gem_cut_icons/gem_cut_heart.png",
    gemCutRadiantIcon: "/assets/gem_cut_icons/gem_cut_radiant.png",
    gemCutPrincessIcon: "/assets/gem_cut_icons/gem_cut_princess.png",
    gemCutEmeraldCutIcon: "/assets/gem_cut_icons/gem_cut_emerald.png",
    gemCutAsscherIcon: "/assets/gem_cut_icons/gem_cut_asher.png",
    gemCutCushionIcon: "/assets/gem_cut_icons/gem_cut_cushion.png",
    gemCutBaguetteIcon: "/assets/gem_cut_icons/gem_cut_baguette.png",
    gemCutTaperedBaguetteIcon: "/assets/gem_cut_icons/gem_cut_tapered_baguette.png",
    gemCutTriangleIcon: "/assets/gem_cut_icons/gem_cut_triangel.png",

    aboutOurBrandBanner: "/assets/banners/about_our_brand_banner.png",
    braceletsListingBanner: "/assets/banners/bracelets_listing_banner.png",
    bridalListingBanner: "/assets/banners/bridal_listing_banner.png",
    certificatedDiamondsListingBanner: "/assets/banners/certificated_diamonds_listing_banner.png",
    earringsListingBanner: "/assets/banners/earrings_listing_banner.png",
    engagementListingBanner: "/assets/banners/engagement_listing_banner.png",
    fantasyCutDiamondListingBanner: "/assets/banners/fantasy_cut_diamond_listing_banner.png",
    highJewelleryListingBanner: "/assets/banners/high_jewellery_listing_banner.png",
    menListingBanner: "/assets/banners/men_listing_banner.png",
    naturalGemstonesListingBanner: "/assets/banners/natural_gemstones_listing_banner.png",
    necklacesListingBanner: "/assets/banners/necklaces_listing_banner.png",
    newInListingBanner: "/assets/banners/new_in_listing_banner.png",
    pendantListingBanner: "/assets/banners/pendant_listing_banner.png",
    ringsListingBanner: "/assets/banners/rings_listing_banner.png",
    yellowDiamondsBanner: "/assets/banners/yellow_diamonds_banner.png",
    engagementAndBridalDropdown: "/assets/engagement_and_bridal_dropdown.jpg",
    menDropdown: "/assets/men_dropdown.png",
    womenDropdown: "/assets/women_dropdown.jpg",
    notFoundImage: "/assets/404.png",
}