import {makeStyles} from "@mui/styles";
import {BASE_COLORS} from "../../../../mainConstants/BASE_COLORS";
import {BASE_FONTS} from "../../../../mainConstants/BASE_FONTS";
import {NavLink} from "react-router-dom";

const useStyles = makeStyles({
    buttonContainer: {
        width: props => props.containerWidth
    },

    button: {
        fontFamily: props => props.fontFamily || BASE_FONTS.playFairDisplay.bold,
        fontWeight: "bold",
        fontSize: props => `${props.fontSize}px`,
        lineHeight: "24px",
        color: BASE_COLORS.purple,
        backgroundColor: props => props.backgroundColor || "transparent",
        border: "1px solid #12131A",
        textAlign: "center",
        padding: props => props.padding || "16px",
        width: props => props.width,
        height: props => props.height,
        borderRadius: props => props.radius,

        '&:disabled': {
            opacity: "0.5",
            cursor: "default"
        }
    },

    darkButton: {
        color: BASE_COLORS.lightGray,
        backgroundColor: props => props.backgroundColor || BASE_COLORS.purple,
        border: "none",
    }
})

function CustomButton({
                          title, action, isDark, padding, width, height, fontSize = 16, fontFamily,
                          navLink, containerWidth, backgroundColor, radius, isDragging, dragProps, isDisabled
}) {
    const styles = useStyles({ padding, width, height, containerWidth, radius, backgroundColor, fontFamily, fontSize })

    return (
        navLink ? <NavLink className={styles.buttonContainer}
                           to={navLink}
                           datatype={"navigation"}>
            {renderButton()}
        </NavLink> : renderButton()
    )

    function renderButton() {
        return <button className={`${styles.button} ${isDark ? styles.darkButton : styles.button}`}
                       onClick={action}
                       style={isDragging ? { color: BASE_COLORS.green } : null}
                       disabled={isDisabled}
                       {...dragProps}>
            {title}
        </button>
    }
}

export default CustomButton