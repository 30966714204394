export class LocalDatabase {

    static updateFavouriteProducts(products) {
        window.localStorage.setItem("favouriteProducts", JSON.stringify(products))
    }

    static setFavouriteProduct(product) {
        const products = this.getFavouriteProducts() || []
        const exists = products.some((el) => el.id === product.id);

        if (exists) {
            const productIndex = products.findIndex((el) => el.id === product.id);
            products.splice(productIndex, 1)
        } else {
            products.push(product)
        }

        window.localStorage.setItem("favouriteProducts", JSON.stringify(products))
    }

    static deleteFavouriteProduct(product) {
        const products = this.getFavouriteProducts() || []
        const exists = products.some((el) => el.id === product.id);

        if (exists) {
            const productIndex = products.findIndex((el) => el.id === product.id);
            products.splice(productIndex, 1)
        }

        window.localStorage.setItem("favouriteProducts", JSON.stringify(products))
    }

    static isFavouriteProduct(product) {
        let products = this.getFavouriteProducts() || []
        return products.find(el => el?.id === product?.id) !== undefined
    }

    static getFavouriteProducts() {
        return JSON.parse(window.localStorage.getItem("favouriteProducts"))
    }

    static setTemporaryToken(token) {
        window.localStorage.setItem("temporaryToken", token)
    }

    static getTemporaryToken() {
        return window.localStorage.getItem("temporaryToken")
    }

    static deleteTemporaryTokenFromDatabase() {
        window.localStorage.removeItem("temporaryToken")
    }

    static setToken(token) {
        window.localStorage.setItem("token", token)
    }

    static getToken() {
       return window.localStorage.getItem("token")
    }

    static deleteTokenFromDatabase() {
        window.localStorage.removeItem("token")
    }

    static setUserInfo(info) {
        window.localStorage.setItem("userInfo", JSON.stringify(info))
    }

    static setUser(user) {
        window.localStorage.setItem("user", JSON.stringify(user))
    }

    static getUser() {
        return JSON.parse(window.localStorage.getItem("user"))
    }

    static setEmailForResetPassword(email) {
        window.localStorage.setItem("email", email)
    }

    static getEmailForResetPassword() {
        return window.localStorage.getItem("email")
    }

    static deleteEmailFromDatabase() {
        window.localStorage.removeItem("email")
    }

    static logout() {
        window.localStorage.removeItem("token")
        window.localStorage.removeItem("user")
        window.localStorage.removeItem("favouriteProducts")
    }
}