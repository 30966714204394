export const BASE_COLORS = {
    white: "#FFFFFF",
    black: "#000000",
    purple: "#1C1827",
    orangeGray: "#F6F4F3",
    gray: "#E7E7EA",
    lightGray: "#F9F9F9",
    darkGray : "#A4A3A8",
    lightBlue: "#AFB7CF",
    beige: "#F7F4F3",
    red: "#B4322B",
    green: "#105510"
}