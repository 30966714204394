export const BASE_ROUTERS = {
    main: "/",
    admin: "/admin",
    account: "/account",
    confirmCode: "/confirm-password",
    resetPassword: "/reset-password",
    changedPassword: "/password-changed",
    register: "/account/register",
    privacyPolicy: "/privacy-policy",
    searchedProducts: "/searchedProducts",
    product: "/product",
    favourite: "/favourites",
    engraving: "/engraving",
    about: {
        en: "about",
        rus: "o-nas",
        arm: "mer-masin"
    },
    location: {
        en: "location",
        rus: "raspolozheniye",
        arm: "gteq-mez"
    },
    exchange: {
        en: "repair-exchange",
        rus: "remont-obmen",
        arm: "veranorogum-poxanakum"
    },
    shipping: {
        en: "shipping",
        rus: "dostavka",
        arm: "araqum"
    },
    individualOrder: {
        en: "individual-order",
        rus: "individual-zakaz",
        arm: "anhatakan-patver"
    },
}