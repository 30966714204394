import { BASE_ROUTERS } from "../../mainConstants/BASE_ROUTERS";
import { useRoutes } from "react-router-dom";
import Language from "./Language";
import asyncComponent from "../main/hooks/asyncComponent";
import { GetCurrentCountry } from "../../api/GetCurrentCountry";
import { useEffect, useState } from "react";
import NotFoundPage from "../pages/NotFoundPage";

const MainRoute = asyncComponent(() => import("../routes/MainRoute"));
const AccountRoute = asyncComponent(() => import("../routes/AccountRoute"));
const RegisterRoute = asyncComponent(() => import("../routes/RegisterRoute"));
const PrivacyPolicyRoute = asyncComponent(() => import("../routes/PrivacyPolicyRoute"));
const ConfirmCodeRoute = asyncComponent(() => import("../routes/ConfirmCodeRoute"));
const ResetPasswordRoute = asyncComponent(() => import("../routes/ResetPasswordRoute"));
const PasswordChangedRoute = asyncComponent(() => import("../routes/PasswordChangedRoute"));
const LocationRoute = asyncComponent(() => import("../routes/LocationRoute"));
const FavouritesRoute = asyncComponent(() => import("../routes/FavouritesRoute"));
const AboutPageRoute = asyncComponent(() => import("../routes/AboutPageRoute"));
const EngravingRoute = asyncComponent(() => import("../routes/EngravingRoute"));
const ExchangeRoute = asyncComponent(() => import("../routes/ExchangeRoute"));
const ShippingRoute = asyncComponent(() => import("../routes/ShippingRoute"));
const IndividualOrderRoute = asyncComponent(() => import("../routes/IndividualOrderRoute"));
const SearchedProductsRoute = asyncComponent(() => import("../routes/SearchedProductsRoute"));
const AdminDashboard = asyncComponent(() => import("../pages/admin/AdminDashboard"));
const ProductRoute = asyncComponent(() => import("../routes/ProductRoute"));
const ProductsRoute = asyncComponent(() => import("../routes/ProductsRoute"));

function Router({ footerHeight, token, setToken }) {

    const getCurrentCountryRequest = new GetCurrentCountry()
    const [languagePrefix, setLanguagePrefix] = useState("")

    useEffect(() => {
        const fetchData = async () => {
            try {
                const data = await getCurrentCountryRequest.sendRequest()
                const countryCode = data.country

                let prefix
                switch (countryCode) {
                    case 'RU':
                    case 'KZ':
                    case 'BY':
                    case 'UA':
                        prefix = 'ru'
                        break
                    case 'AM':
                        prefix = null
                        break
                    default:
                        prefix = 'en'
                }

                setLanguagePrefix(prefix)
            } catch (error) {
                console.error(error)
            }
        }

        const _ = fetchData()
    }, [])

    const languageRoutes = [languagePrefix, "en", "ru", ""].map(language => ({
        element: <Language />,
        children: getRoutes(language)
    }))

    const routes = languageRoutes.reduce((allRoutes, languageRoute) => {
        allRoutes.push(languageRoute)
        let routes = allRoutes.concat(languageRoute.children)

        return routes
    }, [])

    function getRouteElements(prefix) {
        return [
            { path: `*`, element: <NotFoundPage/> },
            { path: `${prefix}${BASE_ROUTERS.main}`, element: <MainRoute footerHeight={footerHeight} /> },
            { path: `${prefix}${BASE_ROUTERS.account}`, element: <AccountRoute token={token} setToken={setToken} footerHeight={footerHeight} /> },
            { path: `${prefix}${BASE_ROUTERS.register}`, element: <RegisterRoute footerHeight={footerHeight} /> },
            { path: `${prefix}${BASE_ROUTERS.favourite}`, element: <FavouritesRoute footerHeight={footerHeight} /> },
            { path: `${prefix}${BASE_ROUTERS.privacyPolicy}`, element: <PrivacyPolicyRoute footerHeight={footerHeight} /> },
            { path: `${prefix}${BASE_ROUTERS.confirmCode}`, element: <ConfirmCodeRoute footerHeight={footerHeight} /> },
            { path: `${prefix}${BASE_ROUTERS.resetPassword}`, element: <ResetPasswordRoute footerHeight={footerHeight} /> },
            { path: `${prefix}${BASE_ROUTERS.changedPassword}`, element: <PasswordChangedRoute footerHeight={footerHeight} /> },
            { path: `${prefix}${BASE_ROUTERS.searchedProducts}`, element: <SearchedProductsRoute footerHeight={footerHeight} /> },
            { path: `${prefix}${BASE_ROUTERS.admin}`, element: <AdminDashboard /> },
            { path: `${prefix}${BASE_ROUTERS.engraving}`, element: <EngravingRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/${BASE_ROUTERS.exchange.en}`, element: <ExchangeRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/${BASE_ROUTERS.exchange.rus}`, element: <ExchangeRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/${BASE_ROUTERS.exchange.arm}`, element: <ExchangeRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/${BASE_ROUTERS.shipping.en}`, element: <ShippingRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/${BASE_ROUTERS.shipping.rus}`, element: <ShippingRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/${BASE_ROUTERS.shipping.arm}`, element: <ShippingRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/${BASE_ROUTERS.individualOrder.en}`, element: <IndividualOrderRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/${BASE_ROUTERS.individualOrder.rus}`, element: <IndividualOrderRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/${BASE_ROUTERS.individualOrder.arm}`, element: <IndividualOrderRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/${BASE_ROUTERS.about.en}`, element: <AboutPageRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/${BASE_ROUTERS.about.rus}`, element: <AboutPageRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/${BASE_ROUTERS.about.arm}`, element: <AboutPageRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/${BASE_ROUTERS.location.en}`, element: <LocationRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/${BASE_ROUTERS.location.rus}`, element: <LocationRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/${BASE_ROUTERS.location.arm}`, element: <LocationRoute footerHeight={footerHeight} /> },
            { path: `${prefix}/:productTypeParam`, element: <ProductsRoute footerHeight={footerHeight} /> },
            { path: `${prefix}${BASE_ROUTERS.product}/:itemCode`, element: <ProductRoute footerHeight={footerHeight} /> },
        ]
    }

    function getRoutes(language) {
        const languagePrefix = `/${language}`

        return getRouteElements(languagePrefix)
    }

    return useRoutes(routes)
}

export default Router
