import {GLOBAL_STYLES} from "./GLOBAL_STYLES";

export const BASE_MEDIA = {
    extraSmallScreen: '(max-width:370px)',
    smallScreen: '(max-width:440px)',
    middleScreen: '(max-width:768px)',
    largeScreen: '(max-width:1024px)',
    extraMidScreen: '(max-width:968px)',
    extraLargeScreen: `(max-width:${GLOBAL_STYLES.containerWidth}px)`,
    width1100: '(max-width:1100px)',
}